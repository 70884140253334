/* General */
body {
	font-size: 0.85rem !important;
}
a {
	text-decoration: none !important;
}
.cursor-pointer {
	cursor: pointer;
}
.dropdown-item {
	font-size: 0.85rem;
}
mark,
.mark {
	padding: 0 !important;
	background-color: #f6e58d !important;
}

/* Extend Bootstrap */
.top-auto {
	top: auto !important;
}
.bottom-auto {
	bottom: 0 !important;
}
.start-auto {
	left: auto !important;
}
.end-auto {
	right: auto !important;
}

/* Header */
.navbar-brand-sidebar {
	padding: 0.75rem !important;
	font-size: 1rem !important;
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

/* Sidebar */
.sidebar {
	padding: 0 !important;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
.sidebar-sticky {
	position: sticky;
	top: 55px;
	height: calc(100vh - 55px);
	padding: 0.5rem 0;
	overflow-x: hidden;
	overflow-y: auto;
}
@media (max-width: 767px) {
	.sidebar-sticky {
		height: auto;
	}
}
.sidebar .nav-link {
	font-weight: 500;
	color: #333;
}
.sidebar-heading {
	font-size: 0.75rem;
	text-transform: uppercase;
}

/* Required labels */
label.required:not(.custom-control-label)::after,
legend.required::after {
	content: "*";
	color: #dc3545;
	margin-left: 3px;
}

/* Avatars */
.avatar {
	object-fit: cover;
	display: inline-block;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}
.avatar,
.avatar-32 {
	width: 32px !important;
	height: 32px !important;
	max-height: 32px;
	font-size: 20px;
	line-height: 27px;
}
.avatar-16 {
	width: 16px !important;
	height: 16px !important;
	max-height: 16px;
	font-size: 10px;
	line-height: 14px;
}
.avatar-24 {
	width: 24px !important;
	height: 24px !important;
	max-height: 24px;
	font-size: 15px;
	line-height: 20px;
}
.avatar-48 {
	width: 48px !important;
	height: 48px !important;
	max-height: 48px;
	font-size: 31px;
	line-height: 44px;
}
.avatar-64 {
	width: 64px !important;
	height: 64px !important;
	max-height: 64px;
	font-size: 40px;
	line-height: 58px;
}
.avatar-96 {
	width: 96px !important;
	height: 96px !important;
	max-height: 96px;
	font-size: 64px;
	line-height: 85px;
}
.avatar-128 {
	width: 128px !important;
	height: 128px !important;
	max-height: 128px;
	font-size: 80px;
	line-height: 115px;
}

/* Media */
.dropzone {
	min-height: auto;
	font-weight: 200;
}
.media-content {
	margin: 15px;
	width: auto !important;
}
.media-thumbnail {
	width: 100px !important;
	height: 100px !important;
	position: relative;
	padding: 0.25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
}
.media-container.media-form .media-thumbnail {
	cursor: pointer;
	z-index: 0;
}
.media-container.media-form .media-thumbnail:after {
	background-color: #fff;
	content: "";
	position: absolute;
	top: 0.25rem;
	left: 0.25rem;
	right: 0.25rem;
	bottom: 0.25rem;
	z-index: -1;
}
.media-thumbnail img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	background-color: #ccc;
}
.media-thumbnail i {
	font-size: 60px;
	position: absolute;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%);
}
.media-thumbnail span {
	display: block;
	font-size: 14px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-transform: uppercase;
	color: white;
	font-weight: bold;
}
.media-thumbnail .media-clear {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	width: 25px;
	height: 25px;
	border-radius: 0.25rem;
	background-color: #7f8c8d;
	color: #fff;
	text-align: center;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	cursor: pointer;
}
.media-thumbnail .media-clear::before {
	content: "\f00d";
}
.media-title {
	text-align: center;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100px;
	margin: 0 auto;
	cursor: default;
}
a[data-bs-toggle="mediaInfo"] {
	cursor: pointer;
}
.modal-body .modal-file .img-fluid,
.lightbox .lb-container {
	background-color: #ccc;
}

/* Select2 */
.select2-container--bootstrap-5.select2-container--open {
	z-index: 9999;
}

/* SweetAlert */
.swal2-actions button:focus {
	box-shadow: none !important;
}

/* Flatpickr */
.form-control.date-picker[readonly] {
	background: none;
}

/* jQuery Bar Rating */
.br-theme-bars-1to10 .br-widget {
	height: 28px;
}
.br-theme-bars-1to10 .br-widget .br-current-rating {
	font-size: 18px;
	line-height: 1.5;
	height: 28px;
	min-width: 49.7px;
}
.br-theme-bars-movie .br-widget .br-current-rating {
	clear: none;
	width: 50px;
	padding: 0;
	float: left;
	margin-top: -8px;
	min-height: 24px;
}
.br-theme-bars-square .br-widget {
	height: 34px;
}
.br-theme-bars-square .br-widget a {
	line-height: 1.8;
}
.br-theme-bars-horizontal .br-widget .br-current-rating {
	line-height: 1.5;
	min-height: 27px;
}

/* Table Responsive */
@media (max-width: 991px) {
	/* Force table to not be like tables anymore */
	table.responsive-mode,
	table.responsive-mode thead,
	table.responsive-mode tbody,
	table.responsive-mode th,
	table.responsive-mode td,
	table.responsive-mode tr {
		display: block;
	}
	/* Hide table headers (but not display: none;, for accessibility) */
	table.responsive-mode thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	table.responsive-mode tr {
		border: 1px solid #ccc;
	}
	table.responsive-mode td[data-label] {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
		white-space: normal;
		text-align: right !important;
	}
	table.responsive-mode td[data-label] .media-thumbnail {
		margin-left: auto;
		margin-right: 0;
	}
	table.responsive-mode td[data-label] .form-check input {
		float: right;
	}
	table.responsive-mode td[data-label]:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		text-align: left;
		font-weight: bold;
	}
	/* Label the data */
	table.responsive-mode td[data-label]:before {
		content: attr(data-label);
	}
}
